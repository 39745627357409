@font-face {
  font-family: 'DMSans-Regular';
  src: local('DMSans-Regular'), url('./DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: local('DMSans-Medium'), url('./DMSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-MediumItalic';
  src: local('DMSans-MediumItalic'),
    url('./DMSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Italic';
  src: local('DMSans-Italic'), url('./DMSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: local('DMSans-Bold'), url('./DMSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-BoldItalic';
  src: local('DMSans-BoldItalic'),
    url('./DMSans-BoldItalic.ttf') format('truetype');
}
